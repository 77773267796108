@import "colors.scss";
@import "grid.scss";
@import "primeng/resources/themes/nova/theme.css";
$footerHeight: 45px;
html {
    position: relative;
    min-height: 100%;
}

body {
    padding-top: 0rem !important;
    margin-bottom: $footerHeight !important;
    /* footer height */
    color: $i_black;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Open Sans', sans-serif !important
    }
    ;
    h1 {
        font-size: 40px !important
    }
    ;
    h2 {
        font-size: 32px !important
    }
    ;
    h3 {
        font-size: 28px !important
    }
    ;
    h4 {
        font-size: 24px !important
    }
    ;
    h5 {
        font-size: 20px !important
    }
    ;
    h6 {
        font-size: 16px !important
    }
    ;
    color: $i_black;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
}

.container-fluid {
    height: 100%;
}

.container-fluid::after {
    content: "";
    display: table;
    clear: both;
}

.footer {
    border-top: 1px solid $i_darkgrey;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footerHeight;
}

.footer div p {
    vertical-align: middle;
    line-height: $footerHeight;
}

.footer div p span.link {
    font-size: small;
    margin-left: 20px;
    margin-right: 20px;
}

.footer div p span.copyright {
    font-size: x-small;
}

.tooltip.show {
    opacity: 1.0;
}

img.logomark {
    margin-top: 5px;
}

img.logo {
position: absolute;
top: 15px;
left: 15px;
}

p.ta-c {
    vertical-align: middle;
    line-height: $footerHeight;
}

span.link {
    font-size: small;
    margin-left: 20px;
    margin-right: 20px;
}

.legal {
    font-size: x-small;
}

hr {
    width: 110%;
    height: 3px;
    background: $i_red;
    margin-top: 0px;
    margin-bottom: 0px;
}


/* Margins/Padding for IRSF */

.margin-sm-horz {
    margin-left: 15px;
    margin-right: 15px;
}

.margin-xs-horz {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-sm-left {
    margin-left: 15px;
}

.margin-left {
    margin-left: 30px;
}

.margin-xs-top {
    margin-top: 5px;
}

.margin-top {
    margin-top: 15px;
}

.margin-lg-top {
    margin-top: 30px;
}

.margin-top-left {
    margin-top: 15px;
    margin-left: 15px;
}

.margin-sm-right {
    margin-right: 15px;
}

.margin-sm-bottom {
    margin-bottom: 20px;
}

.padding-zero-horz {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.padding-md-horz {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

a {
    text-decoration: none !important;
}
/* Links - iconectiv theme*/

a.disabled {
    color: gray;
    cursor: not-allowed;
    text-decoration: underline;
}

a.link {
    color: $i_red !important;
    text-decoration: none !important;
    font-size: 13px;
    cursor: pointer;
}

a.link:focus,
a.link:hover {
    color: #931225 !important;
    text-decoration: underline !important;
}

a.showMoreLess-link {
    color: $i_accent3 !important;
    text-decoration: none !important;
    font-size: 13px;
    cursor: pointer;
    //margin-bottom: 20px !important;
}

/* Tabs */

.nav {
    width: 1500px;
}
.navbar-default .navbar-nav>li> a:active,
.nav-active {
    border-bottom: 2px solid $i_red;
}

.navbar-default .navbar-nav>li>a:hover,
.nav-active {
    border-bottom: 2px solid $i_red;
}

.navbar-default .navbar-nav>li>a {
    color: $i_black;
}

.navbar-default {
    background-color: $i_white;
    border-color: $i_white;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    background-color: $i_white !important;
    border-color: $i_red;
}

.toolTipText {
    font-size: 12px;
    font-family: 'Open Sans';
    font-weight: regular;
}
.dialog-close {
    display: block;
    height: auto;
    width: 350px;
    overflow: auto;
}
.close-button {
    position: absolute;
    right: 1px;
    top: -2px;
}


/* Buttons - iconectiv theme*/

.padding-5 {
    padding: 5px;
}

.partition-btn {
    width:100%;
    height: 2em;
}

.btn {
    text-transform: uppercase !important;
    -webkit-border-radius: 20px !important;
    border-radius: 20px !important;
    // Reduce padding to make button smaller, different from iconectiv theme
    // padding: 10px 30px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    -webkit-transition: .5s;
    transition: .5s;
}

.btn.mobile {
    padding: 11px 30px;
}

.btn-default {
    background: $i_red !important;
    border: 1px solid $i_red !important;
    color: $i_white !important;
}

.btn-default:focus {
    background: $i_red !important;
    color: $i_white !important;
}

.btn-default:hover {
    background: $i_hover_red !important;
    border: 1px solid $i_hover_red !important;
    color: $i_white !important;
}

.btn-primary {
    background: $i_accent3 !important;
    border-color: $i_accent3 !important;
    color: $i_white !important;
}

.btn-primary:hover {
    background: $i_accent3_border !important;
    border-color: $i_accent3_border !important;
    color: $i_white !important;
}

.btn-info {
    background: $i_white !important;
    border-color: $i_accent3_border !important;
    color: $i_accent3 !important;
}

.btn-info:hover {
    background: $i_accent3 !important;
    border-color: $i_accent3_border !important;
    color: $i_white !important;
}

.btn-warning {
    background: $i_accent2 !important;
    border-color: $i_accent2 !important;
    color: $i_white !important;
}

.btn-warning:hover {
    background: $i_accent2_border !important;
    border-color: $i_accent2_border !important;
    color: $i_white !important;
}

.btn-inverse {
    border: 1px solid $i_red !important;
    background: $i_white !important;
    color: $i_red !important;
}

.btn-inverse:hover {
    border: 1px solid $i_red !important;
    background: $i_red !important;
    color: $i_white !important;
}

.btn-inverse-no-border {
    background: $i_white !important;
    color: $i_red !important;
}

.btn-achromatic {
    background: #000;
    color: $i_white;
}

.btn-achromatic:hover {
    background: $i_white;
    border: 1px solid #000;
    color: #000;
}

.btn-disabled {
    background: $i_lightgrey !important;
    border: 1px solid $i_lightgrey !important;
    color: $i_mediumgrey;
}

.btn-disabled:focus,
.btn-disabled:hover {
    background: $i_lightgrey !important;
    border: 1px solid $i_lightgrey !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $i_mediumgrey;
    cursor: not-allowed;
}

.btn-link {
    color: $i_red !important;
}

.btn-link:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000;
}

.btn-link-visited {
    color: $i_accent3;
    text-decoration: underline;
}

.btn-link-disabled {
    color: $i_lightgrey !important;
    cursor: not-allowed;
}

.btn-link-disabled:hover {
    color: $i_lightgrey !important;
    text-decoration: none;
}

// Special button for logout, different from iconectiv theme
.btn-logout {
    text-transform: uppercase !important;
    -webkit-border-radius: 20px !important;
    border-radius: 20px !important;
    padding: 6px 15px !important;
    font-size: 14px !important;
    -webkit-transition: .5s;
    transition: .5s;
    border: 1px solid $i_red !important;
    background: $i_white !important;
    color: $i_red !important;
    margin-top: 8px;
}

.btn-logout:hover {
    border: 1px solid $i_red !important;
    background: $i_red !important;
    color: $i_white !important;
}


/* PrimeNG Customization - iconectiv theme */

body .p-paginator {
    padding: 0;
    box-sizing: border-box;
    border: none;
}

body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
    min-height: 0.875em;
    min-width: 2em;
    padding: 0.5em 0.5em;
    vertical-align: top;
    color: $i_red;
    border-color: $i_red;
    padding: 4px 18px;
    background-color: none;
    border-radius: 15px;
}

body .p-paginator .p-paginator-first:hover,
body .p-paginator .p-paginator-prev:hover,
body .p-paginator .p-paginator-next:hover,
body .p-paginator .p-paginator-last:hover {
    background-color: $i_red;
    color: $i_white !important;
    border-color: $i_red;
}

body .p-paginator .p-paginator-first:before,
body .p-paginator .p-paginator-prev:before,
body .p-paginator .p-paginator-next:before,
body .p-paginator .p-paginator-last:before {
    position: relative;
    top: 0.063em;
}

body .p-paginator .p-paginator-pages {
    padding: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2em;
    border-color: $i_red;
    padding: 0px 18px; //changed from 4px 18px
    background-color: none;
    border-radius: 15px;
}

body .p-paginator .p-paginator-pages .p-paginator-page .ui-state-active,
body .p-paginator .p-paginator-pages .p-paginator-page:hover,
body .p-paginator .p-paginator-pages .p-paginator-page:hover.ui-state-active {
    background: $i_red !important;
    color: $i_white !important;
    border-color: $i_red;
}

body .ui-state-active,
body .p-highlight {
    background-color: $i_red !important;
    color: $i_white;
}

body .p-button {
    text-transform: uppercase !important;
    -webkit-border-radius: 20px !important;
    border-radius: 20px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    -webkit-transition: .5s;
    transition: .5s;
}

body .p-button {
    background: $i_red !important;
    border: 1px solid $i_red !important;
    color: $i_white !important;
    margin-right: 0px !important;
}

body .p-button:focus {
    background: $i_red !important;
    color: $i_white !important;
}

body .p-button:hover {
    background: $i_hover_red !important;
    border: 1px solid $i_hover_red !important;
    color: $i_white !important;
}

body .p-button-text-icon-left .p-button-text {
    padding: 0em;
}

body .ui-state-active,
body .p-highlight {
    background-color: $i_red;
    border-color: $i_red;
    color: $i_white;
}
body .p-datatable-thead tr th {
    background-color: $i_darkgrey !important;
    border-color: $i_white !important;
    color: $i_white !important;
    font-weight: bold important; 
}
body .p-datatable thead th.ui-state-active {
    background-color: $i_red;
    border-color: $i_red;
    color: $i_white;
}

body .p-datatable .p-datatable-data tr.p-datatable-even.p-highlight {
    background-color: $i_accent2;
    color: $i_white;
    border-color: $i_red;
}

body .p-datatable .p-datatable-data tr.p-datatable-odd.p-highlight {
    background-color: $i_accent2;
    color: $i_white;
    border-color: $i_red;
}
.highlight {
    background-color: $i_accent2;
    color: $i_white;
    border-color: $i_red;
    padding-top:0px;
}
body .p-datatable .fa-chevron-circle-right:before {
    content: '\F054';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color:$i_red;
}
body .p-datatable .fa-chevron-circle-down:before {
    content: '\F078';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color:$i_accent3 !important;
}
 
body .p-datatable .p-datatable-data .p-editable-column {
    background-color: $i_white;
    color: $i_black;
    border-color: $i_lightgrey;
}

body .p-dialog .p-dialog-content {
    overflow: hidden;
}

body .p-dialog .p-dialog-content .row {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 15px;
}

.center {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

/* Toggle Switch */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -10px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $i_accent3;
    -webkit-transition: .4s;
    transition: .4s;
    height:26px;
    width:60px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: $i_white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: $i_red;
}

input:focus+.slider {
    box-shadow: 0 0 1px $i_red;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.no-shadow {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19) !important;
    cursor: pointer;
}

.list-group-item-info {
    color: #31708f;
    background-color: white;
}

.list-group-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem 1.25rem;
    margin-bottom: 5px;
    background-color: #fff;
    border: 0px;
}

.list-group-horizontal .list-group-item {
    display: inline-block;
}

.list-group-horizontal .list-group-item {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0;
    border-right-width: 0;
    border: 0px solid #ddd;
    padding: 0px 1px;
}

.list-group-item a {
    color: black;
}

.list-group-item a.selected {
    color: red;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-right-width: 1px;
}
.filter-group-box {
    display: flex;
    -ms-flex-flow: column wrap !important;
    flex-flow: column wrap !important;
}
.tp-filter-group-box {
    min-height: 800px;
    width: 500px;
}
.background-gray {
    background-color: #ddd !important;
}

.lists-horiz-padding {
    padding-left: 20px !important;
    padding-right: 0px !important;
}

.lists-group-horiz-padding {
    padding-left: 10px !important;
    padding-right: 0px !important;
}

.container-fluid>.lists-menu {
    position: relative;
    top: 0;
    left: auto;
    width: 250px;
}

.container-fluid>.groups-menu {
    position: relative;
    top: 0;
    left: auto;
    width: 300px;
}

.left {
    float: left;
}

.container-fluid>.content {
    margin: 0 0 0 250px;
}

.customer-date {
    width: 89px !important;
    overflow: visible !important;
}

// File Upload related 
.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

// Rules Page
.fa-asterisk {
    color: $i_red;
}

.help-text {
    font-size: 14px !important;
}

.inline {
    display: inline;
}

// Legal Acknowledgement Modal

.sub-header {
    font-size: 12px;
}

body .p-inputtext {
    font-size: 11px !important;
}
//word break in datatable
.p-datatable-data> tr> td {
    word-wrap: break-word; 
    white-space: normal;
}
.spacer-left20{
    margin-left:20px;
}
.spacer-top20{
    margin-top:20px;
}
.p-overlaypanel{
    top : 260px !important;
    left: 160px !important;
} 
.no-uppercase {
    text-transform: none !important;
}
.no-padding{
    padding:0 0 !important;
}
.list-btn{
    height: 26px;
    width:105px;
}
.push-to-blocking-btn{
    height:26px;
    width:125px
}
.download-btn{
    height:26px;
    width:95px;
}
body .p-radiobutton .p-radiobutton-box.ui-state-active{
    border :1px solid #d71b36;
    background-color : #d71b36 ;
}
body .p-radiobutton .p-radiobutton-box{
    border :1px solid #d71b36;
}
body .p-multiselect .p-multiselect-item.p-highlight {
    background-color: rgba(72,76,85, .5);
    color: #000000;
}
body .p-multiselect .p-multiselect-item.p-highlight .p-checkbox {
    border: 1px solid rgba(72,76,85, .5);
}
body .p-checkbox .p-checkbox-box.ui-state-active {
    border: 1px solid rgba(72,76,85, .5);
    background-color: rgba(72,76,85, .5);
    color: #000000;
}
.p-multiselect .p-multiselect-label {
    font-weight: normal;
}
label {
    font-weight: normal;
}
.custom .p-dialog-titlebar{
    font-size:20px !important; 
}
.red{
    color:$i_red;
}
.warningDialog{
    background-color: $i_accent2 !important;  
    color: white !important;
    min-height: 0px !important;
} 
.errorDialog{
    background-color: $i_red !important;  
    color: white !important;
    min-height: 0px !important;
}
.text-button,
.text-button:focus,
.text-button:active {
    border: none;
    background: none;
    outline: none;
}
.leftrail-leftbar {
    border-left: 1px solid $i_darkgrey !important;
}
.leftrail-rightbar {
    border-right: 1px solid $i_darkgrey !important;
}
.alpha-button {
    width: 50px;
    height: 20px;
    padding: 0px 5px !important;
    margin-bottom: 5px;
}
.filter-selected {
    background-color: rgba(255,78,0, .3);
    color: #000000;
}
 
//adapted from dropdown-toggle::after in bootstrap.css
.nav-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: "";
    border-top: 0 !important;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  .p-input-overlay {
  background-color:#424242;
  opacity:0.7;
  width: 100%;
  min-height:800px;
  z-index: 1003;
}

.p-picklist-caption {
    font-weight:bold !important;
    width: 180px;
  }
  
  .p-picklist-buttons {
    padding:10px !important;
  }

  .p-picklist-buttons .p-button {
    border-radius: 8px !important;
    padding: 5px 15px !important;
  }


  /* Toast Settings */
  .p-toast {
    position: fixed;
    top: 50px;
    right: 20px;
    width: 20em;
  }
  .custom-toast-width {
    width: 400px; /* Adjust the width as needed */
  }
  body .p-toast .p-toast-message {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin: 0 0 1em 0; }
    body .p-toast .p-toast-message.p-toast-message-info {
      background-color: #7fbcec;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-message.p-toast-message-info .p-toast-close-icon {
        color: #212121; }
    body .p-toast .p-toast-message.p-toast-message-success {
      background-color: #b7d8b7;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-message.p-toast-message-success .p-toast-close-icon {
        color: #212121; }
    body .p-toast .p-toast-message.p-toast-message-warn {
      background-color: #ffe399;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-message.p-toast-message-warn .p-toast-close-icon {
        color: #212121; }
    body .p-toast .p-toast-message.p-toast-message-error {
      background-color: #f8b7bd;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-message.p-toast-message-error .p-toast-close-icon {
        color: #212121; }

.p-dataview .p-dataview-header {
    border-bottom: 0 none;
    display: none;
}

/* Table Format */
body .p-datatable-thead tr th {
    background-color: $i_darkgrey !important;
    border-color: $i_white !important;
    color: $i_white !important;
    font-weight: bold important; 
}
body .p-datatable thead th.p-state-active {
    background-color: $i_red;
    border-color: $i_red;
    color: $i_white;
}

body .p-datatable .p-datatable-data tr.p-datatable-even.p-highlight {
    background-color: $i_accent2;
    color: $i_white;
    border-color: $i_red;
}

body .p-datatable .p-datatable-data tr.p-datatable-odd.p-highlight {
    background-color: $i_accent2;
    color: $i_white;
    border-color: $i_red;
}

.p-datatable .p-datatable-caption {
    background-color: white !important;
    border: 0px !important;
    text-align: left !important;
}

body .p-datatable .p-datatable-tfoot {
    text-align: center;
}

.highlight {
    background-color: $i_accent2;
    color: $i_white;
    border-color: $i_red;
    padding-top:0px;
}
body .p-datatable .fa-chevron-right:before {
    content: '\F054';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color:$i_red;
}
body .p-datatable .fa-chevron-down:before {
    content: '\F078';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color:$i_accent3 !important;
}
 
body .p-datatable .p-table-data .p-editable-column {
    font-size: 11px !important;
    background-color: $i_white;
    color: $i_black !important;
    border-color: $i_lightgrey;
}

body .p-datatable .p-editable-column {
    font-size: 11px !important;
    background: white !important;
    color: #000;
    cursor: pointer;
}

body .p-datatable .p-editable-column input {
    font-size: 11px !important;
    background: white !important;
    color: #000;
    border: none;
    outline: none !important;
}

//body .p-datatable .p-editable-column > tr.p-highlight {
tr.p-highlight td.p-editable-column {
    border: 1px solid rgba(255,78,0, .3) !important;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: $i_selected_orange !important;
    border-color: $i_selected_orange !important;
    color: $i_black !important;
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):hover {
    cursor: pointer;
    background-color:$i_hover_orange !important;
    color: $i_black !important;
  }

  
body .p-component {
    font-size: 11px !important;
}


// bootstrap 5 upgrade changes
.navbar-nav {
    flex-direction: row;
}

.navbar {
    min-height: 50px;
    border: 1px solid transparent;
}

@media (min-width: 576px) {
    .navbar {
        border-radius: 0.25rem;
    }
}

@media (min-width: 768px) {
    .navbar {
        border-radius: 4px;
    }
    .navbar-header {
        float: left;
    }

    .navbar-toggle {
        display: none;
    }
}

label {
    margin-bottom: 5px;
}

.btn {
    white-space: nowrap;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
    display: block;
}

#main-nav {
    display: block;
}

.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
}

.pull-right {
    float: right !important;
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.p-datatable .p-datatable-header {
    background: unset;
    border: none;
}

.btn {
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.padding-5 {
    padding: 5px;
}

.list-group {
    display: block;
}
  
.list-group {
    // height: 500px;
    overflow-y: auto;
    li {
        border: none;
    }
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border: none;
}

body .p-dataview-content p {
    line-height: 1.5;
    margin: 0;
}

@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
    }
}

body a {
    color: #007ad9;
}

.p-picklist .p-picklist-list {
    width: 100% !important;
}

table td[class*="col-"], table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}

hr {
    opacity: 1;
}

.custom .p-dialog-title {
    font-size: 20px !important;
}

.p-datatable-resizable-table>.p-datatable-thead>tr>th, .p-datatable-resizable-table>.p-datatable-tfoot>tr>td, .p-datatable-resizable-table>.p-datatable-tbody>tr>td {
    white-space: unset !important;
}

.range-overlay {
    min-width: 75%;
}

body .p-datatable .p-datatable-tbody > tr > td {
    background-color: inherit;
    border: 1px solid #c8c8c8;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tfoot>tr>td {
    text-align: center;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #f9f9f9;
}

body .p-datatable .p-datatable-tbody > tr {
    background-color: #ffffff;
    color: #333333;
    transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-datatable .p-datatable-tfoot > tr > td {
    padding: 0.571em 0.857em;
    border: 1px solid #c8c8c8;
    font-weight: 700;
    color: #333333;
    background-color: #ffffff;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}