$i_white: white;
$i_lightgrey: #a3a7a9;
$i_mediumgrey: #797d82;
$i_darkgrey: #484c55;
$i_black: #000;
$i_red: #d71b36;
$i_hover_red: #5b0b17;

$i_accent1: #ff0000;
$i_accent2: #ff4e00;
$i_accent3: #33647f;
$i_accent4: #415464;
$i_accent5: #768693;

$i_accent3_border: #1d3a49;
$i_accent2_border: #992e00;

$i_error_red: red;
$i_error_red_border: #881123;

$i_selected_orange: rgba(255,78,0, .3);
$i_hover_orange: rgba(255,78,0, .15);